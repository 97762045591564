import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  ReportDataMap,
  ReportDataModel,
  ReportSection,
  ReportSectionConfigurationWithOrder,
  ReportType,
  SAMPLE_DATA_PROFILE_ID
} from '../..';
import { map, of, tap } from 'rxjs';
import { ListItem } from '@capital-access/lists/common';

@Injectable()
export class ReportDataService {
  constructor(private http: HttpClient) {}

  private cachedSampleReportData: Partial<Record<ReportType, ReportDataMap>> = {};

  private baseUrl = '/api/reporting';

  getReportData(
    reportSections: ReportSectionConfigurationWithOrder[],
    security: {
      id: number;
      surveillance: boolean;
    } | null,
    culture: string,
    language: string,
    currency: string | null,
    timeZone: string,
    selectedInstitution?: ListItem
  ) {
    if (!reportSections.length) {
      return of({} as ReportDataMap);
    }
    const securityId = security?.id;
    const dataSource = security?.surveillance ? 'Surveillance' : 'Public';
    const reportType = reportSections[0].config.appliedReportType;
    const sections = reportSections
      .filter(
        item =>
          item.config.reportSection !== ReportSection.None &&
          item.isSelected &&
          reportType &&
          item.config.applicableReportTypes.includes(reportType)
      )
      .map(section => section.config.reportSection);
    if (selectedInstitution) {
      const profileId =
        reportType === ReportType.Institution
          ? `${selectedInstitution.prId}|${selectedInstitution.crmId}`
          : `${selectedInstitution.prId ?? selectedInstitution.crmId}|`;
      return this.loadReportData(
        sections,
        reportType,
        profileId,
        false,
        securityId,
        dataSource,
        timeZone,
        currency,
        culture,
        language
      );
    } else {
      return this.loadReportData(
        sections,
        reportType,
        '',
        true,
        securityId,
        dataSource,
        timeZone,
        currency,
        culture,
        language
      );
    }
  }
  loadReportData(
    sectionsIn: ReportSection[],
    reportType: ReportType = ReportType.Institution,
    profileIdIn: string = '',
    pullSampleData = true,
    securityId: number = 115620,
    dataSource: string,
    timeZone: string,
    currency: string | null,
    culture: string,
    language: string
  ) {
    const profileId = profileIdIn !== '' ? profileIdIn : SAMPLE_DATA_PROFILE_ID;
    const sections = sectionsIn;
    const body = {
      profileId,
      securityId,
      timeZone,
      reportType,
      dataSource,
      sections,
      currency,
      culture,
      language,
      sectionSettings: {
        EquityPortfolioTopBuysSellsGrid: '5',
        EquityPortfolioTopHoldingsGrid: '5'
      },
      pullSampleData
    };
    if (profileId === SAMPLE_DATA_PROFILE_ID && this.cachedSampleReportData[reportType]) {
      return of(this.cachedSampleReportData[reportType]);
    }

    return this.http.post<ReportDataModel[]>(`${this.baseUrl}/configurable-reporting/profile/raw-data`, body).pipe(
      map(reportDataList => {
        const reportData: ReportDataMap = reportDataList.reduce((acc, { key, value }) => {
          acc[key] = value;
          return acc;
        }, {} as ReportDataMap);
        return reportData;
      }),
      tap(reportData => {
        if (profileId === SAMPLE_DATA_PROFILE_ID) {
          this.cachedSampleReportData[reportType] = reportData;
        }
      })
    );
  }
}
