import { AsyncPipe, NgClass } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Optional, Output } from '@angular/core';
import { EMPTY, Observable, of } from 'rxjs';
import { FireflyLocalizationService } from '../utils/localization/firefly-localization.service';
import { pluralize } from '../utils/localization/pluralize';
import { COLUMNS_MIN_SELECTED_COUNT, COLUMNS_TOTAL_COUNT } from './layout-width.constants';
import { LayoutWidthColumn } from './layout-width.model';

@Component({
  selector: 'f-layout-width',
  templateUrl: './layout-width.component.html',
  standalone: true,
  imports: [AsyncPipe, NgClass],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FireflyLayoutWidthComponent implements OnInit {
  @Input() set minAllowedColumns(count: number) {
    if (count < COLUMNS_MIN_SELECTED_COUNT) {
      this._minAllowedColumns = COLUMNS_MIN_SELECTED_COUNT;
      console.warn(
        `minAllowedColumns can't be less then ${COLUMNS_MIN_SELECTED_COUNT}. Value changed to ${COLUMNS_MIN_SELECTED_COUNT}`
      );
    } else this._minAllowedColumns = count;

    this.setMinDisabledTitle();
    this.setColumns();
  }

  @Input() set maxAllowedColumns(count: number) {
    if (count > COLUMNS_TOTAL_COUNT) {
      this._maxAllowedColumns = COLUMNS_TOTAL_COUNT;
      console.warn(
        `minAllowedColumns can't be greater then ${COLUMNS_TOTAL_COUNT}. Value changed to ${COLUMNS_TOTAL_COUNT}`
      );
    } else this._maxAllowedColumns = count;

    this.setMaxDisabledColumnTitle();
    this.setColumns();
  }

  @Input() set selectedColumnsCount(count: number) {
    if (count < this._minAllowedColumns) {
      this._selectedColumnsCount = this._minAllowedColumns;
      console.warn(
        `selectedColumnsCount can't be less then minAllowedColumns. Value changed to ${this._minAllowedColumns}`
      );
    } else if (count > this._maxAllowedColumns) {
      this._selectedColumnsCount = this._maxAllowedColumns;
      console.warn(
        `selectedColumnsCount can't be greater then maxAllowedColumns. Value changed to ${this._maxAllowedColumns}`
      );
    } else this._selectedColumnsCount = count;

    this.setColumns();
  }

  @Input() disabled = false;

  @Output() selectedColumnsCountChange = new EventEmitter<number>();

  constructor(@Optional() private localizationService: FireflyLocalizationService) {}

  private _minAllowedColumns = COLUMNS_MIN_SELECTED_COUNT;
  private _maxAllowedColumns = COLUMNS_TOTAL_COUNT;
  private _selectedColumnsCount = COLUMNS_MIN_SELECTED_COUNT;
  columns: LayoutWidthColumn[] = [];
  minDisabledColumnTitle$: Observable<string> = EMPTY;
  maxDisabledColumnTitle$: Observable<string> = EMPTY;

  ngOnInit(): void {
    this.setMinDisabledTitle();
    this.setMaxDisabledColumnTitle();
    this.setColumns();
  }

  onSelectColumnsCount(columnIndex: number) {
    if (columnIndex >= this._maxAllowedColumns) return;
    if (columnIndex + 1 < this._minAllowedColumns) return;

    this.columns = this.columns.map((column, index) =>
      index <= columnIndex || index < this._minAllowedColumns
        ? { ...column, selected: true }
        : { ...column, selected: false }
    );

    this.selectedColumnsCountChange.emit(columnIndex + 1);
  }

  get minColumns() {
    return this._minAllowedColumns;
  }

  get maxColumns() {
    return this._maxAllowedColumns;
  }

  private setColumns() {
    this.columns = Array(COLUMNS_TOTAL_COUNT)
      .fill({})
      .map(
        (_, index) =>
          ({
            selected: index < this._selectedColumnsCount,
            disabled: index < this._minAllowedColumns - 1 || index >= this._maxAllowedColumns
          } as LayoutWidthColumn)
      );
  }

  private setMaxDisabledColumnTitle() {
    if (this.localizationService) {
      this.maxDisabledColumnTitle$ = this.localizationService.localize(
        pluralize('maxDisabledColumn', this._maxAllowedColumns),
        { columnCount: this._maxAllowedColumns }
      );
    } else {
      this.maxDisabledColumnTitle$ = of(
        pluralize(`This section cannot be more than ${this._maxAllowedColumns} column`, this._maxAllowedColumns)
      );
    }
  }

  private setMinDisabledTitle() {
    if (this.localizationService) {
      this.minDisabledColumnTitle$ = this.localizationService.localize(
        pluralize('minDisabledColumn', this._minAllowedColumns),
        { columnCount: this._minAllowedColumns }
      );
    } else {
      this.minDisabledColumnTitle$ = of(
        pluralize(`This section cannot be less than ${this._minAllowedColumns} column`, this._minAllowedColumns)
      );
    }
  }
}
