import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ModalContent } from '../../../modal';

@Component({
  selector: 'f-collapsed-popover',
  templateUrl: './collapsed-popover.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FireflyCollapsedPopoverComponent implements ModalContent {
  modalInstance!: NgbActiveModal;
  context!: {
    valuePath: string;
    linkPath: string;
    itemCssClass: string;
    items: Record<string, unknown>[];
    invalidItems: Record<string, unknown>[];
    onRemove: (i: unknown) => void;
    onItemClick: (i: unknown) => void;
  };

  @Input() valuePath: string = 'text';
  @Input() linkPath: string = 'link';
  @Input() itemCssClass: string = '';
  @Input() items: Record<string, unknown>[] | null = [];
  @Input() invalidItems: Record<string, unknown>[] | null = [];

  @Output() remove = new EventEmitter<Record<string, unknown>>();
  @Output() itemClick = new EventEmitter<Record<string, unknown>>();

  get itemsRef() {
    return this.context ? this.context.items : this.items;
  }

  get invalidItemsRef(): Record<string, unknown>[] | null {
    return this.context ? this.context.invalidItems : this.invalidItems;
  }

  get valuePathRef() {
    return this.context ? this.context.valuePath : this.valuePath;
  }

  get linkPathRef() {
    return this.context ? this.context.linkPath : this.linkPath;
  }

  get itemCssClassRef() {
    return this.context ? this.context.itemCssClass : this.itemCssClass;
  }

  onRemove(item: Record<string, unknown>) {
    this.context ? this.context.onRemove(item) : this.remove.emit(item);
  }

  onItemClick(item: Record<string, unknown>) {
    this.context ? this.context.onItemClick(item) : this.itemClick.emit(item);
  }
}
