import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { filter, map, switchMap, take, tap, withLatestFrom } from 'rxjs/operators';
import {
  CompanyParticipantsIds,
  createProfileReportFinished,
  openParticipantProfileReportDrawer,
  openProfileReportDrawer
} from '@capital-access/common/actions';
import { getFeatureToggle } from '@capital-access/common/feature-toggle';
import { LocalizationService } from '@capital-access/common/localization';
import { getSelectedRelatedSecurities } from '@capital-access/common/security';
import {
  DrawerConfig,
  DrawerOutput,
  DrawerPosition,
  DrawerRef,
  DrawerType,
  FireflyDrawerService
} from '@capital-access/firefly/components';
import { ProfileType } from '@capital-access/profiles/common';
import { LOCALIZATION_KEY } from '../../constants';
import { SectionSelectorListItem } from '../../core/models/report-sections';
import { getSavedLayout, getSavedSections } from '../../core/utils/section-settings.utils';
import { ProfileReportDrawerComponent } from '../components/profile-report-drawer/profile-report-drawer.component';
import { ProfileReportDrawerV2Component } from '../components/profile-report-drawer-v2/profile-report-drawer-v2.component';
import { CreateProfileReportDto } from '../models/profile-report.models';
import {
  DEFAULT_LAYOUT,
  getConditionalReportSections,
  getDefaultSections,
  ProfileReportLayout
} from '../models/profile-report-sections';

@Injectable()
export class ProfileReportDrawerEffects {
  openDrawer$ = createEffect(() => this.actions$.pipe(ofType(openProfileReportDrawer), this.openDrawer()));

  openParticipantDrawer$ = createEffect(() =>
    this.actions$.pipe(ofType(openParticipantProfileReportDrawer), this.openDrawer(true))
  );

  private openDrawer(participantReport = false) {
    return (
      source: Observable<{
        profileType: ProfileType;
        profiles: { prId?: number | null; crmId?: number | null; name?: string | null }[];
        combinedSecurityIds?: number[];
        includedContacts?: CompanyParticipantsIds[];
      }>
    ) =>
      source.pipe(
        withLatestFrom(
          this.store.select(getSelectedRelatedSecurities),
          this.localization.getLocalization(LOCALIZATION_KEY),
          this.store.select(getFeatureToggle('profile-report-historical-ownership-SKYL-2377')),
          this.store.select(getFeatureToggle('profile-report-sustainability-fit-ISSYN-2489')),
          this.store.select(getFeatureToggle('reporting-primary-contact-biography-section-cos-6429')),
          this.store.select(getFeatureToggle('combined-ownership-cora-3277')),
          this.store.select(getFeatureToggle('one-pager-report-cos-6493')),
          this.store.select(getFeatureToggle('one-pager-contact-report-ISD-6105'))
        ),
        switchMap(
          ([
            action,
            relatedSecurities,
            localization,
            isHistoricalOwnershipToggleEnabled,
            isSustainabilityFitToggleEnabled,
            isPrimaryContactBiographyToggleEnabled,
            isCombinedOwnershipToggleEnabled,
            onePagerEnabled,
            onePagerContactEnabled
          ]) => {
            const conditionalSections = getConditionalReportSections({
              historicalOwnership: action.profileType !== ProfileType.Contact || isHistoricalOwnershipToggleEnabled,
              sustainabilityFitMetric:
                action.profileType === ProfileType.Institution && isSustainabilityFitToggleEnabled,
              primaryContactBiography:
                !participantReport &&
                action.profileType === ProfileType.Institution &&
                isPrimaryContactBiographyToggleEnabled,
              participantBiography:
                participantReport &&
                action.profileType === ProfileType.Institution &&
                isPrimaryContactBiographyToggleEnabled,
              combinedOwnership:
                isCombinedOwnershipToggleEnabled &&
                action.profileType === ProfileType.Institution &&
                relatedSecurities?.length >= 2
            });

            const request = CreateProfileReportDto.create({
              profiles: action.profiles,
              profileType: action.profileType,
              includedContacts: action.includedContacts,
              combinedSecurityIds: action.combinedSecurityIds || relatedSecurities.map(x => x.id)
            });
            const filteredSections = getSavedSections(
              action.profileType,
              getDefaultSections(action.profileType as ProfileType),
              conditionalSections
            );

            const drawerConfig = {
              title: localization[`${action.profileType}DrawerHeader`],
              position: DrawerPosition.Right,
              type: DrawerType.Form,
              dataLayoutId: 'modify-profile-report-drawer'
            };

            let drawer: DrawerRef;
            if (
              (onePagerEnabled && action.profileType === ProfileType.Institution) ||
              (onePagerContactEnabled && action.profileType === ProfileType.Contact)
            ) {
              const onePagerSections = getSavedSections(
                `${action.profileType}${ProfileReportLayout.OnePager}`,
                getDefaultSections(action.profileType, ProfileReportLayout.OnePager),
                conditionalSections
              );
              drawer = this.buildProfileV2Drawer(
                filteredSections,
                onePagerSections,
                request,
                drawerConfig,
                action.profileType
              );
            } else {
              drawer = this.buildProfileDrawer(filteredSections, request, drawerConfig);
            }

            return drawer.onClose().pipe(
              take(1),
              filter((output: DrawerOutput) => !output || !output['reportInProgress']),
              map(() => createProfileReportFinished())
            );
          }
        )
      );
  }

  private buildProfileDrawer(
    filteredSections: SectionSelectorListItem[],
    request: CreateProfileReportDto,
    drawerConfigs: DrawerConfig
  ) {
    return this.drawerService.openDrawer(drawerConfigs, ProfileReportDrawerComponent, {
      request,
      filteredSections
    });
  }

  private buildProfileV2Drawer(
    filteredSections: SectionSelectorListItem[],
    onePagerSections: SectionSelectorListItem[],
    request: CreateProfileReportDto,
    drawerConfigs: DrawerConfig,
    profileType: ProfileType
  ) {
    const layout = getSavedLayout(profileType, DEFAULT_LAYOUT);

    return this.drawerService.openDrawer(drawerConfigs, ProfileReportDrawerV2Component, {
      request,
      filteredSections: {
        [ProfileReportLayout.Detailed]: filteredSections,
        [ProfileReportLayout.OnePager]: onePagerSections
      },
      layout: layout as ProfileReportLayout
    });
  }

  constructor(
    private actions$: Actions,
    private localization: LocalizationService,
    private drawerService: FireflyDrawerService,
    private store: Store
  ) {}
}
