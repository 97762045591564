import { Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { getFeatureToggle } from '@capital-access/common/feature-toggle';
import { Store } from '@ngrx/store';
import { map, take } from 'rxjs';
import { Breakpoint } from '@capital-access/firefly/components';

@Injectable()
export class CanAccessReportingGuard {
  constructor(
    private router: Router,
    private store: Store,
    @Inject('IS_REPORTING_ENABLED') private readonly isReportingEnabledFeatureToggle$: string
  ) {}

  get isMobile(): boolean {
    return (
      /Mobi|Android|iPhone|iPad|iPod|Windows Phone/i.test(navigator.userAgent) || window.innerWidth < Breakpoint.Sm
    );
  }

  canActivate() {
    return this.store.select(getFeatureToggle(this.isReportingEnabledFeatureToggle$)).pipe(
      map(isEnabled => {
        if (isEnabled && !this.isMobile) {
          return true;
        } else {
          this.router.navigate(['/']);
          return false;
        }
      }),
      take(1)
    );
  }

  canActivateChild() {
    return this.canActivate();
  }
}
