import { createAction, props } from '@ngrx/store';

export const addActivity = createAction('[Common Activity] Add Activity');
export const addToActivity = createAction(
  '[Common Activity] Add To Activity',
  props<{
    contacts?: { prId?: number | null; crmId?: number | null }[];
    institutions?: { prId?: number | null; crmId?: number | null }[];
  }>()
);

export const editActivity = createAction(
  '[Common Activity] Edit Activity',
  props<{ activityId: string; addBackButton?: boolean }>()
);
export const editActivitySuccess = createAction(
  '[Common Activity] Edit Activity Success',
  props<{ activityId: string; eventId?: string }>()
);

export const addActivityWithPayload = createAction(
  '[Common Activity] Add Activity With Payload',
  props<{ eventId?: string; utcDate?: string; scheduleId?: number }>()
);

export const addActivitySuccess = createAction(
  '[Common Activity] Add Activity Success',
  props<{ activityId: string; eventId?: string }>()
);

export const deleteActivitySuccess = createAction(
  '[Common Activity] Delete Activity Success',
  props<{ activityId: string }>()
);
