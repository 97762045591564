export enum OnboardingEmptyStatesOptions {
  Meet = 'meet',
  Measure = 'measure',
  Merge = 'merge',
  OnboardingMail = 'onboarding-mail',
  Reporting = 'reporting'
}

export enum SimpleActionableEmptyStatesOptions {
  Calendar = 'calendar',
  Crm = 'crm',
  Edit = 'edit',
  Contact = 'contact',
  Toggles = 'toggles',
  Teams = 'teams',
  Institution = 'institution',
  Esg = 'esg',
  CustomFields = 'custom-fields',
  PrimaryContact = 'primary-contact',
  Import = 'import'
}

export enum SimpleEmptyStatesOptions {
  Fund = 'fund',
  Crm = 'crm',
  Contact = 'contact',
  Institution = 'institution',
  Calendar = 'calendar',
  Securities = 'securities',
  Targeting = 'targeting',
  Merge = 'merge',
  EditHistory = 'edit-history',
  Esg = 'esg',
  CustomFields = 'custom-fields',
  Mail = 'mail'
}

export enum NoDataOptions {
  SomethingWentWrong = 'something-went-wrong',
  DataUnavailable = 'data-unavailable',
  NoDataToDisplay = 'no-data-to-display',
  NoResultsMatchCriteria = 'no-results-match-criteria'
}
