import { ReportSection } from '../../core/models/report-sections';
import { GroupedSectionSelectorListItem } from './itinerary-groupped-section-item';

const DEFAULT_ITINERARY_SECTIONS: GroupedSectionSelectorListItem[] = [
  {
    id: ReportSection.Institution,
    isToggleDisabled: true,
    isSelected: true,
    isFixed: true,
    disabled: true,
    isRequired: true,
    localizationKey: 'institution'
  },
  {
    id: ReportSection.InstitutionType,
    isSelected: false,
    isFixed: true,
    disabled: true,
    localizationKey: 'institutionType'
  },
  {
    id: ReportSection.ExternalParticipants,
    isToggleDisabled: true,
    isSelected: true,
    isFixed: true,
    disabled: true,
    isRequired: true,
    localizationKey: 'externalParticipants'
  },
  {
    id: ReportSection.ExternalParticipantJobRole,
    isSelected: false,
    isFixed: true,
    disabled: true,
    localizationKey: 'externalParticipantJobRole'
  },
  {
    id: ReportSection.Ownership,
    localizationKey: 'ownershipSection',
    isSelected: false,
    isFixed: false,
    nestedItems: [
      {
        id: ReportSection.SharesReportedDate,
        isSelected: true,
        localizationKey: 'sharesReportedDate'
      },
      {
        id: ReportSection.SharesChange,
        isSelected: true,
        localizationKey: 'shareChange'
      },
      {
        id: ReportSection.HolderRank,
        isSelected: false,
        localizationKey: 'holderRank'
      },
      {
        id: ReportSection.OwnershipValue,
        isSelected: false,
        localizationKey: 'ownershipValue',
        hasCurrencySymbol: true
      },
      {
        id: ReportSection.ShareChangeValue,
        isSelected: false,
        localizationKey: 'shareChangeValue',
        hasCurrencySymbol: true
      },
      {
        id: ReportSection.SharesOutstandingPercentage,
        isSelected: false,
        localizationKey: 'sharesOutstandingPercentage'
      },
      {
        id: ReportSection.PortfolioPercentage,
        isSelected: false,
        localizationKey: 'portfolioPercentage'
      }
    ]
  },
  {
    id: ReportSection.InstitutionDetails,
    localizationKey: 'institutionDetails',
    isSelected: true,
    nestedItems: [
      {
        id: ReportSection.Orientation,
        isSelected: false,
        localizationKey: 'orientation'
      },
      {
        id: ReportSection.Style,
        isSelected: false,
        localizationKey: 'styleSection'
      },
      {
        id: ReportSection.Turnover,
        isSelected: false,
        localizationKey: 'turnover'
      },
      {
        id: ReportSection.EquityAssets,
        isSelected: true,
        localizationKey: 'equityAssets',
        hasCurrencySymbol: true
      },
      {
        id: ReportSection.City,
        isSelected: false,
        localizationKey: 'city'
      },
      {
        id: ReportSection.Country,
        isSelected: false,
        localizationKey: 'country'
      }
    ]
  },
  {
    id: ReportSection.Targeting,
    localizationKey: 'targeting',
    isSelected: false,
    isFixed: false,
    nestedItems: [
      {
        id: ReportSection.InstitutionRating,
        isSelected: true,
        localizationKey: 'institutionRating'
      },
      {
        id: ReportSection.SuitabilityScore,
        isSelected: true,
        localizationKey: 'suitabilityScore'
      },
      {
        id: ReportSection.PurchasingPower,
        isSelected: true,
        localizationKey: 'purchasingPower'
      },
      {
        id: ReportSection.BestFund,
        isSelected: false,
        localizationKey: 'bestFund'
      }
    ]
  },
  {
    id: ReportSection.InteractionWithInstitution,
    localizationKey: 'interactionWithInstitution',
    isSelected: false,
    isFixed: false,
    nestedItems: [
      {
        id: ReportSection.LastDateMet,
        isSelected: true,
        localizationKey: 'lastDateMet'
      },
      {
        id: ReportSection.InstitutionInteractions,
        isSelected: true,
        localizationKey: 'institutionInteractions'
      }
    ]
  },
  {
    id: ReportSection.ActivityCompanyContacts,
    isSelected: true,
    disabled: true,
    isFixed: true,
    localizationKey: 'companyContacts'
  },
  {
    id: ReportSection.ActivityNotes,
    isSelected: true,
    disabled: true,
    isFixed: true,
    localizationKey: 'activityNotes'
  },
  {
    id: ReportSection.ActivityCustomFields,
    isSelected: true,
    disabled: true,
    isFixed: true,
    localizationKey: 'activityCustomFields'
  }
];

export function getDefaultSections(): GroupedSectionSelectorListItem[] {
  return DEFAULT_ITINERARY_SECTIONS;
}
