import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { map, switchMap } from 'rxjs/operators';
import { ActivityInputs } from '@capital-access/activity/form';
import { addActivity, addActivityWithPayload, addToActivity, editActivity } from '@capital-access/common/actions';
import { CommonLazyLoadingService } from '@capital-access/common/lazy-loading';
import { ACTIVITY_FORM_MODULE_ID, LOAD_ACTIVITY_FORM_MODULE } from './activity-form-module-id.model';

@Injectable()
export class ActivityFormEffects {
  openActivityForm$ = createEffect(() =>
    this.actions$.pipe(
      ofType(addActivity),
      switchMap(() => this.openForm())
    )
  );

  openActivityFormWithParticipants$ = createEffect(() =>
    this.actions$.pipe(
      ofType(addToActivity),
      switchMap(args => {
        return this.openForm(args);
      })
    )
  );

  openActivityFormWithPayload$ = createEffect(() =>
    this.actions$.pipe(
      ofType(addActivityWithPayload),
      switchMap(args => this.openForm(args))
    )
  );

  openEditActivityForm$ = createEffect(() =>
    this.actions$.pipe(
      ofType(editActivity),
      switchMap(({ activityId, addBackButton }) => this.openForm({ activityId }, addBackButton))
    )
  );

  private openForm(inputs?: ActivityInputs, addBackButton?: boolean) {
    return this.commonLazyLoadingService
      .loadModule(ACTIVITY_FORM_MODULE_ID, LOAD_ACTIVITY_FORM_MODULE)
      .pipe(map(moduleRef => moduleRef.instance.openActivityForm(inputs, addBackButton)));
  }

  constructor(private actions$: Actions, private commonLazyLoadingService: CommonLazyLoadingService) {}
}
