<ng-container [caLocalizationScope]="localizationScope">
  <div class="heading-20 mb-2">{{ 'dataPoints' | caLocalize }}</div>
  <p class="mb-2">{{ 'itineraryReportDescription' | caLocalize }}</p>
  <form [formGroup]="form">
    <ca-itinerary-group-sections-selector formControlName="sections" (settingsChange)="settingsChange($event)">
    </ca-itinerary-group-sections-selector>
    <ca-set-as-default-toggle
      [control]="setAsDefaultControl"
      [toggleLabel]="'itineraryToggleLabel'"
    ></ca-set-as-default-toggle>
  </form>
  <ng-template fDrawerFooterTemplate>
    <ca-modify-report-drawer-footer
      [disableSaving]="disableSaving"
      [createReportTitle]="'createItineraryReport'"
      (closeDrawer)="close()"
      (createReport)="createReport()"
    >
    </ca-modify-report-drawer-footer>
  </ng-template>
</ng-container>
