import { ChangeDetectorRef, Pipe, PipeTransform } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { map, Observable, of } from 'rxjs';
import { getFeatureToggle } from '@capital-access/common/feature-toggle';
import { BaseAsyncPipe } from '@capital-access/common/utils';
import { MAX_REPORT_PROFILES_COUNT, MAX_REPORT_PROFILES_COUNT_WITH_FT } from '../constants';

@Pipe({ name: 'caIsProfileReportingDisabled' })
export class IsProfileReportingDisabledPipe extends BaseAsyncPipe<boolean, number | null> implements PipeTransform {
  constructor(private store: Store, changeDetectorRef: ChangeDetectorRef) {
    super(changeDetectorRef);
  }
  getTransformer(count: number | null): Observable<boolean> {
    if (count === null) return of(false);
    return this.store.select(getFeatureToggle('increase-export-report-limit-to-100-isd-5807')).pipe(
      map(exportReportLimitFtEnabled => {
        const limit = exportReportLimitFtEnabled ? MAX_REPORT_PROFILES_COUNT_WITH_FT : MAX_REPORT_PROFILES_COUNT;
        return !(count > 0 && count <= limit);
      })
    );
  }
}
