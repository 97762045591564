import { ChangeDetectorRef, Component, EventEmitter, Input, Output, TemplateRef } from '@angular/core';
import {
  DragTargetDragEndEvent,
  DragTargetDragEvent,
  DragTargetDragStartEvent,
  DragTargetPressEvent,
  DropTargetEvent
} from '@progress/kendo-angular-utils';
import { Breakpoint } from '../../../utils';
import { DraggableItem, PreventDragArgs } from '../models';

@Component({ template: '' })
export abstract class FireflyBaseDraggableListComponent {
  @Input() preventDragFn!: (({ dragTarget, dropTarget }: PreventDragArgs<DraggableItem>) => boolean) | undefined;
  @Input() rootCssClass = '';
  @Input() draggableContentClass = '';
  @Input() listItemTemplate!: TemplateRef<unknown>;
  @Input() listItemRootClass = 'align-items-center';
  @Input() draggableIconClass = '';
  @Input() disabled = false;
  @Input() items!: DraggableItem[];
  @Input() hoverable = true;
  @Input() disabledItemTooltip!: string | null;
  @Input() disabledIcon = false;

  @Output() drop: EventEmitter<unknown[]> = new EventEmitter();
  @Output() actionBtnClick = new EventEmitter();

  draggingElement!: HTMLElement | null;
  dropTargetIdx = -1;
  dragTargetIdx = -1;
  initialPosition = {
    x: 0,
    y: 0
  };
  wasMovement = false;

  get desktopView(): boolean {
    return window.innerWidth >= Breakpoint.Sm;
  }

  constructor(protected cdr: ChangeDetectorRef) {}

  handlePress($event: DragTargetPressEvent) {
    ($event.normalizedEvent.originalEvent.target as HTMLInputElement).focus();
  }

  onDrag(e: DragTargetDragEvent) {
    if (this.wasMovement) return;

    const horizontalMovement = e.dragEvent.clientX - this.initialPosition.x;
    const verticalMovement = e.dragEvent.clientY - this.initialPosition.y;
    if (horizontalMovement !== 0 || verticalMovement !== 0) {
      this.wasMovement = true;
      e.hostElement.classList.add('dragging');
    }
  }

  onNestedListOrderChanged() {
    this.drop.emit(this.items);
  }

  handleDragStart($event: DragTargetDragStartEvent, idx: number) {
    this.initialPosition = {
      x: $event.dragEvent.clientX,
      y: $event.dragEvent.clientY
    };
    const { pointerType } = $event.normalizedEvent.originalEvent as PointerEvent;
    if (pointerType === 'mouse') {
      $event.hostElement.addEventListener('click', this.preventDefault, { capture: true, once: true });
    }
    $event.hostElement.parentElement?.classList.add('dragging-start-place');
    this.draggingElement = $event.hostElement;
    this.dragTargetIdx = idx;

    if (this.preventDragFn) {
      if (this.dragIsPrevented(idx, null)) {
        $event.hostElement.classList.add('not-allowed');
      }
    }
  }

  handleDragEnd($event: DragTargetDragEndEvent) {
    /* Fix checkbox selection on window sharing SKYL-1913 */
    if (!this.wasMovement) this.removeClickEventListener($event.hostElement);
    window.requestAnimationFrame(() => {
      this.wasMovement = false;
      $event.hostElement.classList.remove('dragging');
      $event.hostElement.classList.remove('not-allowed');
      $event.hostElement.parentElement?.classList.remove('dragging-start-place');
      this.removeClickEventListener($event.hostElement);
      this.cdr.detectChanges();
    });
    this.dragTargetIdx = -1;
    this.draggingElement = null;
  }

  handleDragEnter($event: DropTargetEvent, idx: number) {
    if (this.preventDragFn) {
      if (this.dragIsPrevented(this.dragTargetIdx, idx)) {
        this.draggingElement?.classList.add('not-allowed');
      } else {
        this.draggingElement?.classList.remove('not-allowed');
      }
    }

    $event.hostElement.classList.add('new-drop-place');
    this.dropTargetIdx = idx;
  }

  handleDragLeave($event: DropTargetEvent) {
    $event.hostElement.classList.remove('new-drop-place');
  }

  handleDrop($event: DropTargetEvent, idx: number) {
    if (this.dropTargetIdx < 0 || this.dragTargetIdx < 0) return;
    $event.hostElement.classList.remove('new-drop-place');

    if (this.preventDragFn) {
      if (this.dragIsPrevented(this.dragTargetIdx, idx)) {
        this.draggingElement?.classList.remove('not-allowed');
        return;
      }
    }

    const activeItem = this.items[this.dragTargetIdx];
    this.items.splice(this.dragTargetIdx, 1);
    this.items.splice(idx, 0, activeItem);
    this.drop.emit(this.items);
    this.dropTargetIdx = -1;
  }

  protected preventDefault = (e: Event) => {
    e.preventDefault();
  };
  protected removeClickEventListener = (element: HTMLElement) => {
    element.removeEventListener('click', this.preventDefault, { capture: true });
  };

  protected dragIsPrevented(dragTargetIdx: number | null, dropTargetIdx: number | null) {
    if (!this.preventDragFn) return;
    return this.preventDragFn({
      dragTarget: dragTargetIdx !== null ? this.items[dragTargetIdx] : null,
      dropTarget: dropTargetIdx !== null ? this.items[dropTargetIdx] : null
    });
  }
}
