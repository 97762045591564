<div
  ngbDropdown
  #dropdown="ngbDropdown"
  class="multiple-suggester"
  [placement]="placement"
  [container]="modalIsOpen ? null : dropdownContainer"
  [autoClose]="modalIsOpen ? false : 'outside'"
  (openChange)="onOpenChange($event)"
  [ngClass]="{ 'multiple-suggester-with-items': selectedChips.length }"
>
  <div
    #anchor
    ngbDropdownAnchor
    class="input-group-right-icon form-control position-relative"
    [ngClass]="[inputClass, validationClass]"
    [class.is-invalid]="requiredAndInvalid"
    [class.disabled]="disabled"
  >
    <f-editable-chip-group
      class="selected-items"
      [items]="selectedChips"
      [collapsable]="true"
      [disabled]="disabled"
      [valuePath]="valuePath"
      [linkPath]="linkPath"
      [chipCssClass]="chipCssClass"
      [itemCssClass]="itemCssClass"
      [invalidItems]="invalidItems"
      [type]="chipType.EditableSecondary"
      [popoverContainer]="popoverContainer"
      [popoverPlacement]="popoverPlacement"
      [popoverAutoClose]="popoverAutoClose"
      (itemClick)="onItemClick($event)"
      (remove)="onRemoveFromChips($event)"
      (removeAll)="onRemoveAll()"
    >
    </f-editable-chip-group>
    <div class="input-wrapper">
      <input
        #inputElement
        type="text"
        fDropdownListFocus
        [focusableItemsContainer]="resultsBox"
        data-automation-id="multiple-suggester-input"
        [class.disabled]="disabled"
        [placeholder]="placeholder"
        [formControl]="inputValue"
        (focus)="onFocus()"
        (click)="openMobileModal()"
        (focusout)="onFocusOut()"
        (blur)="suggesterManager.onBlur()"
        (select)="$event.stopPropagation()"
      />
      <f-spinner
        *ngIf="suggesterManager.showLoader"
        [spinnerSize]="spinnerSizes.Sm"
        class="position-absolute translate-middle-y top-50 end-0 me-2"
      ></f-spinner>
      <i
        class="f-i f-i-close"
        [class.disabled]="disabled"
        [attr.tabindex]="disabled ? -1 : 0"
        [title]="clearTooltip$ | async"
        (click)="resetValue($event)"
        (keyup.enter)="resetValue($event)"
        *ngIf="suggesterManager.inputControlValue.length && !suggesterManager.showLoader"
      ></i>
    </div>
  </div>

  <div *ngIf="validation?.message" [ngClass]="validationMessageClass">{{ validation?.message }}</div>
  <div *ngIf="requiredAndInvalid" class="invalid-feedback">
    {{ requiredErrorMessage$ | async }}
  </div>

  <div #resultsBox ngbDropdownMenu [fDropdownWidth]="anchor" [dropdown]="dropdown">
    <div class="text-muted border-bottom pb-1 mt-2 selected-items-counter-mobile" *ngIf="maxSelectedItemsCount">
      {{ selectedAndMaxSelectedItemsCount$ | async }}
    </div>
    <f-list #resultsList fSuggesterList class="multiple-suggester-list" [dropdownMaxHeight]="dropdownMaxHeight">
      <ng-container *ngIf="suggesterManager.showList">
        <div #actionButtons class="d-flex align-items-center border-bottom" *ngIf="!maxSelectedItemsCount">
          <span class="text-muted selected-items-counter-mobile">
            {{ selectedItemsCount$ | async }}
          </span>
          <f-action-buttons
            *ngIf="actionsForAllList && listData.totalLength"
            [showSelectAll]="listData.showSelectAll!"
            [showClearAll]="listData.showClearAll!"
            (updateList)="updateListOnActionButtonClick($event, true)"
          >
          </f-action-buttons>
        </div>
        <ng-container *ngFor="let category of listData.groupedItems; let lastGroup = last; trackBy: trackByGroupFn">
          <f-list-item
            [withHover]="false"
            class="multiple-suggester action-wrapper category-name"
            *ngIf="category?.name && category.value.length"
          >
            <span class="heading-20 me-2">{{ category.name }}</span>
            <f-action-buttons
              *ngIf="actionsForCategories && !actionsForAllList"
              [showSelectAll]="category.showSelectAll!"
              [showClearAll]="category.showClearAll!"
              (updateList)="updateListOnActionButtonClick($event, false, category)"
            >
            </f-action-buttons>
          </f-list-item>

          <f-list-item-checkbox
            fDropdownItem
            ngbDropdownItem
            *ngFor="let item of category.value; let firstItem = first; let lastItem = last; trackBy: trackByListFn"
            [ngClass]="{ 'mb-3': category?.name && lastItem && !lastGroup }"
            [tabable]="false"
            [hasSelectedStyles]="true"
            [checkOnEnter]="false"
            [checked]="item.isSelected"
            [disabled]="item.isDisabled || (item | fIsDisabledItem : disabledPath)"
            [title]="item.isDisabled && maxSelectedItemsCount ? (maxSelectedItemsCount$ | async) : ''"
            [attr.category]="category?.name"
            (keydown.enter)="onEnter($event, item, category)"
            (toggle)="onCheckboxChange(item, category)"
          >
            <f-suggester-list-item
              [item]="item"
              [linkPath]="linkPath"
              [valuePath]="valuePath"
              [isSingleListItem]="firstItem && lastItem"
              [suggesterItemTemplate]="suggesterItemTemplate"
              [inputValue]="suggesterManager.inputControlValue"
              (itemClick)="onItemClick($event)"
              class="text-truncate"
            ></f-suggester-list-item>
          </f-list-item-checkbox>
        </ng-container>
      </ng-container>

      <f-list-status-items
        [showError]="showError"
        [showViewMore]="showViewMore"
        [showContactSupportLink]="showContactSupportLink"
        [showLoader]="suggesterManager.showLoader"
        [showNoData]="suggesterManager.showNoData"
        [showNoResults]="suggesterManager.showNoResults"
        [noResultsTemplate]="noResultsTemplate"
        [loaderTemplate]="loaderTemplate"
        [errorTemplate]="errorTemplate"
        [noDataTemplate]="noDataTemplate"
        [viewMoreTemplate]="viewMoreTemplate"
        [footerTemplate]="footerTemplate"
        (viewMore)="suggesterManager.onViewMore()"
        (contactSupport)="suggesterManager.onContactSupport()"
      ></f-list-status-items>
    </f-list>

    <div #resultsFooter *ngIf="footerTemplate" class="multiple-suggester-footer">
      <ng-template [ngTemplateOutlet]="footerTemplate"></ng-template>
    </div>
  </div>
</div>
