import { Inject, Injectable } from '@angular/core';
import { getFeatureToggle } from '@capital-access/common/feature-toggle';
import { NavConfig, NavConfigFn, NavigationConfigProvider } from '@capital-access/common/navigation';
import { Store } from '@ngrx/store';
import { map } from 'rxjs';
import { REPORTING_PATH } from './constants';
import { Breakpoint } from '@capital-access/firefly/components';

@Injectable()
export class ReportingNavConfigProvider implements NavigationConfigProvider {
  constructor(
    private store: Store,
    @Inject('IS_REPORTING_ENABLED') private readonly isReportingEnabledFeatureToggle$: string
  ) {}

  get isMobile(): boolean {
    return (
      /Mobi|Android|iPhone|iPad|iPod|Windows Phone/i.test(navigator.userAgent) || window.innerWidth < Breakpoint.Sm
    );
  }

  resolveNavConfig: NavConfigFn = () => {
    return this.store.select(getFeatureToggle(this.isReportingEnabledFeatureToggle$)).pipe(
      map(isEnabled => {
        return <NavConfig>{
          top: [
            {
              localizationKey: 'reporting',
              icon: 'f-i-report',
              order: 700,
              link: REPORTING_PATH,
              enabled: isEnabled && !this.isMobile
            }
          ]
        };
      })
    );
  };
}
