import { ChangeDetectionStrategy, Component } from '@angular/core';
import { Store } from '@ngrx/store';
import { FireflyDrawerService } from '@capital-access/firefly/components';
import { BaseReportDrawer } from '../../../components/report-drawer/base-report-drawer';
import { SectionSelectorListItem } from '../../../core/models/report-sections';
import { getSelectedSectionsFromGroups } from '../../../core/utils/section-settings.utils';
import { createItineraryCustomReport } from '../../+state/itinerary-report.actions';
import { SETTINGS_KEY } from '../../constants';
import { GroupedSectionSelectorListItem } from '../../models/itinerary-groupped-section-item';
import { CreateItineraryReportDto } from '../../models/itinerary-report.models';

@Component({
  templateUrl: './itinerary-report-drawer.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ItineraryReportDrawerComponent extends BaseReportDrawer<
  CreateItineraryReportDto,
  GroupedSectionSelectorListItem[]
> {
  override getSections(filteredSections: GroupedSectionSelectorListItem[]): SectionSelectorListItem[] {
    return filteredSections;
  }

  get settingsKey(): string {
    return SETTINGS_KEY;
  }

  get createReportAction() {
    const sections = getSelectedSectionsFromGroups(this.form.controls.sections.value);
    return createItineraryCustomReport({
      eventId: this.request!.eventId,
      eventName: this.request!.eventName,
      sections
    });
  }

  constructor(store: Store, drawerService: FireflyDrawerService) {
    super(store, drawerService);
  }
}
