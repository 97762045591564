<div class="d-flex" (click)="$event.stopPropagation()">
  <f-list [withConcisedItems]="true" class="f-collapsed-popover-list">
    <f-list-item-concised
      *ngFor="let item of itemsRef"
      (removeItem)="onRemove(item)"
      [danger]="!!invalidItemsRef?.includes(item)"
    >
      <div
        (click)="onItemClick(item)"
        class="text-truncate w-100"
        [ngClass]="itemCssClassRef"
        title="{{ item | fNestedValue : valuePathRef }}"
        *ngIf="!(item | fNestedValue : linkPathRef)"
        data-automation-id="list-group-item-content"
      >
        {{ item | fNestedValue : valuePathRef }}
      </div>
      <a
        *ngIf="item | fNestedValue : linkPathRef"
        data-automation-id="list-group-item-content"
        class="link link-main text-truncate"
        [href]="item | fNestedValue : linkPathRef"
        title="{{ item | fNestedValue : valuePathRef }}"
        target="_blank"
        >{{ item | fNestedValue : valuePathRef }}</a
      >
    </f-list-item-concised>
  </f-list>
</div>
