import { ChangeDetectionStrategy, Component, EventEmitter, Output } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { noop } from 'rxjs';
import { DraggableItem, PreventDragArgs } from '@capital-access/firefly/components';
import { LOCALIZATION_KEY } from '../../../constants';
import { GroupedSectionSelectorListItem } from '../../models/itinerary-groupped-section-item';

@Component({
  selector: 'ca-itinerary-group-sections-selector',
  templateUrl: './itinerary-report-sections.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      useExisting: ItineraryReportSectionsComponent
    }
  ]
})
export class ItineraryReportSectionsComponent implements ControlValueAccessor {
  @Output()
  settingsChange = new EventEmitter<GroupedSectionSelectorListItem[]>();

  localizationScope = LOCALIZATION_KEY;
  sections: GroupedSectionSelectorListItem[] = [];
  private emitValueChange: (sections: GroupedSectionSelectorListItem[]) => void = noop;
  private markTouched: () => void = noop;

  writeValue(sections: GroupedSectionSelectorListItem[]): void {
    this.sections = sections;
  }

  registerOnChange(fn: (sections: GroupedSectionSelectorListItem[]) => void) {
    this.emitValueChange = fn;
  }

  registerOnTouched(fn: () => void) {
    this.markTouched = fn;
  }

  preventDragFn({ dragTarget, dropTarget }: PreventDragArgs<DraggableItem>): boolean {
    const dragTargetItem = dragTarget as DraggableItem;
    const dropTargetSection = dropTarget as GroupedSectionSelectorListItem;
    if (!dropTargetSection) {
      return false;
    }

    return !!dragTargetItem?.disabled || !!dropTargetSection.isFixed;
  }

  emitValues(value: GroupedSectionSelectorListItem[]) {
    this.emitValueChange(this.sections);
    this.settingsChange.emit(value);
  }

  toggle(section: GroupedSectionSelectorListItem) {
    this.sections = this.sections.map(s => ({
      ...s,
      isSelected: s.id === section.id ? !s.isSelected : s.isSelected,
      nestedItems:
        s.nestedItems && s.nestedItems.some(ns => ns.id === section.id)
          ? s.nestedItems.map(ns => ({ ...ns, isSelected: ns.id === section.id ? !ns.isSelected : ns.isSelected }))
          : s.nestedItems
    }));
    this.emitValues(this.sections);
  }

  onDrop(value: unknown[]) {
    this.sections = value as GroupedSectionSelectorListItem[];
    this.emitValues(this.sections);
  }

  clearAll() {
    this.sections = this.sections.map(s => ({
      ...s,
      isSelected: s.isRequired ? s.isSelected : false,
      nestedItems: !s.nestedItems ? s.nestedItems : s.nestedItems.map(ns => ({ ...ns, isSelected: false }))
    }));
    this.emitValues(this.sections);
  }
}
