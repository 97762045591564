import { ReportType } from './models/report-type.enum';

export const LOCALIZATION_KEY = 'reporting-common';
export const REPORTING_API_BASE_URL = '/api/reporting/';
export const POLLING_INTERVAL = 1000;
export const MAX_SMALL_REPORT_ITEMS_COUNT = 30;
export const MAX_REPORT_PROFILES_COUNT = 50;
export const MAX_REPORT_PROFILES_COUNT_WITH_FT = 100;
export const DEFAULT_TRUNCATION_LENGTH = 5;

export const PAGE_SIZES = [
  {
    value: 'us-letter',
    label: 'usLetter'
  },
  {
    value: 'a4',
    label: 'a4size'
  }
] as const;
export const SAMPLE_DATA_PROFILE_ID = '-1|';

export const REPORT_TITLES: Partial<Record<ReportType, string>> = {
  [ReportType.Contact]: 'contactProfileReport',
  [ReportType.Institution]: 'investorProfileReport',
  [ReportType.HistoricalOwnership]: 'historicalOwnershipReport',
  [ReportType.Itinerary]: 'itineraryReport'
};
