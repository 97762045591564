<f-chip
  *ngFor="let item of visibleChips; let lastItem = last"
  [class.me-1]="!lastItem"
  [type]="getChipType(item)"
  [size]="chipSize"
  [title]="item | fNestedValue : valuePath"
  [disabled]="disabled"
  (chipClick)="onRemove(item)"
  data-automation-id="f-chip"
>
  <span
    *ngIf="!(item | fNestedValue : linkPath)"
    [ngClass]="chipCssClass"
    (click)="onItemClick(item)"
    data-automation-id="list-group-item-content"
    >{{ item | fNestedValue : valuePath }}</span
  >

  <a
    *ngIf="item | fNestedValue : linkPath"
    data-automation-id="list-group-item-content"
    class="link"
    [ngClass]="linkClass"
    [href]="item | fNestedValue : linkPath"
    target="_blank"
    >{{ item | fNestedValue : valuePath }}</a
  ></f-chip
>
<div
  #collapsedChipContainer
  class="d-inline-block f-collapsed-chip"
  [class.d-none]="!collapsed"
  data-automation-id="f-collapsed-chip"
>
  <f-chip
    #popover="ngbPopover"
    [type]="type"
    [size]="chipSize"
    [disabled]="disabled"
    (chipClick)="onRemoveAll()"
    [ngbPopover]="popoverTemplate"
    [popoverClass]="'w-100 collapsed-popover'"
    [placement]="popoverPlacement"
    [container]="popoverContainer"
    [disablePopover]="disabled && !isPartOfSuggester"
    [autoClose]="$any(popoverAutoClose)"
    [triggers]="'manual'"
    [class.active]="popover.isOpen()"
    [class.disabled-suggester-chip]="disabled && isPartOfSuggester"
    (click)="onCollapsedChipClick($event, popover)"
    class="d-block"
  >
    {{ collapsedText$ | async }}
    <ng-template #popoverTemplate>
      <f-collapsed-popover
        *ngIf="collapsed"
        [items]="items"
        [valuePath]="valuePath"
        [linkPath]="linkPath"
        [itemCssClass]="itemCssClass"
        [invalidItems]="invalidItems"
        (itemClick)="onItemClick($event)"
        (remove)="onRemove($event)"
      ></f-collapsed-popover>
    </ng-template>
  </f-chip>
</div>
