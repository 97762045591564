export enum ReportType {
  Institution = 'Institution',
  Contact = 'Contact',
  CapitalInsight = 'CapitalInsight',
  HistoricalOwnership = 'HistoricalOwnership',
  Itinerary = 'Itinerary'
}

export enum ReportActionTitle {
  Create = 'create',
  Modify = 'modify'
}

export const NewReportTemplateTypes = ['InvestorTemplate', 'ContactTemplate'];
